import React from 'react'

const StayInformed = () => {
  return (
    <div className="stay-informed-container">
    <h1 className="stay-informed-title">Stay Informed</h1>
    <p className="stay-informed-description">
      Sign up for developer news, announcements, and more from NVIDIA. Get the latest information on new self-paced courses, 
      instructor-led workshops, free training, discounts, and more. You can unsubscribe at any time.
    </p>
    <button className="subscribe-button">Enroll Now</button>
  </div>
  )
}

export default StayInformed