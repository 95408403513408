import React from "react";
import Navbar from "../Courrces/Navbar";
import StayInformed from "./StayInformed";
import TeamTran from "./TeamTran";
import Catlog from "./Catlog";
import bkg from '../images/course-specific.png';
import { useLocation } from "react-router-dom";
import CourseContentTab from "./CourseContentTab";

const CourcesNav = () => {
  const location = useLocation();
  const courseName=location.pathname.replace("/course/",'').split('%20');
  const courseTitle= courseName.map((final,m)=>final+" ");

  return (
    <div className='specific-course'>
    <header
  style={{
    backgroundImage: `url(${bkg})`,
    height: '25vw',
    display: 'flex',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }}
>
  <div className="header-content">
    <h1>{courseTitle}</h1>
  </div>
</header>


      <CourseContentTab/>
      
      <StayInformed/> 
      <Catlog/>
    </div>
  );
};

export default CourcesNav;
