import React from "react";

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="footer-content">

          <div className="footer-section">
            
            <ul className="footer-list">
              <h5 className="footer-title">DLI Solutions</h5><hr></hr>
              <li className="footer-item">Self-Paced, Online</li>
              <li className="footer-item">Workshops</li>
              <li className="footer-item">Live Instructor-Led</li>
              <li className="footer-item">Courses</li>
              <li className="footer-item">Workshops</li>
              <li className="footer-item">Educator Programs and</li>
              
            </ul>
          </div>

          <div className="footer-section">
            
            <ul className="footer-list">
              <h5 className="footer-title">DLI Solutions</h5><hr></hr>
              <li className="footer-item">Self-Paced, Online</li>
              <li className="footer-item">Workshops</li>
              <li className="footer-item">Live Instructor-Led</li>
              <li className="footer-item">Courses</li>
              <li className="footer-item">Workshops</li>
              <li className="footer-item">Educator Programs and</li>
              
            </ul>
          </div>


         

          <div className="footer-section">
            
            <ul className="footer-list">
            <h5 className="footer-title">Technologies</h5><hr></hr>
              <li className="footer-item">CUDA-X AI</li>
              <li className="footer-item">NGC Catalog</li>
              <li className="footer-item">Data Analytics</li>
              <li className="footer-item">Deep Learning SDKs</li>
              <li className="footer-item">Deep Learning Frameworks</li>
              <li className="footer-item">Conversational AI</li>
              
            </ul>
          </div>

          <div className="footer-section">
           
            <ul className="footer-list">
            <h5 className="footer-title">Resources</h5>
            <hr></hr>
              <li className="footer-item">Technical Resources</li>
              <li className="footer-item">NVIDIA Developer</li>
              <li className="footer-item">NVIDIA Research</li>
              <li className="footer-item">GPU Technology Conference</li>
              <li className="footer-item">Careers</li>
              <li className="footer-item">Newsroom</li>
              
            </ul>
          </div>
        </div>
        <div className='footer-bottom'>
          <ul>
            <li>
              <ul>
                <li><a href="https://acetians.com/about" target="_blank">
                    About Us
                  </a></li>
                <li>
                 <a href="https://acetians.com/contact" target="_blank">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="https://acetians.com/careers" target="_blank">
                    Careers
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <a href="https://acetians.com/privacy-policy" target="_blank">
                    Privacy Policy
                  </a>
                   
                </li>
                <li>
                  <a href="https://acetians.com/contact" target="_blank">
                    Bussines Enquiry
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
