import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import { UserCircleIcon } from '@heroicons/react/16/solid';
import { Person } from '@mui/icons-material';

const NavHeader = () => {
  
  return (
    <>
    <ScrollToTop/>
    <div className='NavHeader'>
            <ul>
                <li>
                    <img className='Nav-Company-logo' src='https://acetians.com/assets/img/logo1.png'></img>    
                </li>

                <li>
                      <Person/>
                </li>
            </ul>
    </div>
    </>
  )
}

export default NavHeader