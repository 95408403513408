import React from 'react'

const Catlog = () => {
  return (
    <div className="catalog-container">
    <h1 className="catalog-title">Course Catalog</h1>
    <button className="catalog-button">View Course Catalog</button>
  </div>
  )
}

export default Catlog